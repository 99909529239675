<template>
  <div class="pseudo-operation">
    <!-- 注塑装备虚拟操作 -->
    <div class="content">
      <h1 id="xncz">注塑装备虚拟操作</h1>

      <div class="second-nav">
        <ul class="second-level-directory" id="second-level-directory">
          <div class="nav-box">
            <li class="nav-link" v-for="nav,index in nav_data" :key="index">
              <a :href="nav.href">{{ nav.name }}</a>
            </li>
          </div>
        </ul>
      </div>
      <div style="height: 30px"></div>

      <div class="industry-introduction" id="introduction" style="margin-top: 80px">
        <h1 style="text-align: center;">系统介绍</h1>
        <div style="height: 40px"></div>
        <p>
          采用VR技术，以动画播放、声音解说与文字介绍三者结合的形式，介绍注射机的工作原理、典型结构、操作过程和故障处理。
        </p>
        <p>
          解决实验教学关键问题：学校注塑机少，不能满足实验教学要求，更方便；虚拟仿真可以模仿实际操作注塑机成形，更安全；虚拟仿真无需消耗塑料，无需成形模具，更经济......
        </p>
        <div style="height: 30px"></div>
        <div style="float: right">
          <a href="http://moldingx.com:8081/"><el-button type="danger" round>了解更多</el-button></a>
        </div>
      </div>
    </div>
    <div style="height: 20px;"></div>

    <div class="core-function" id="corefunction">
      <h1>核心功能</h1>
      <div class="fun-content">
        <ul class="nav">
          <li
            v-for="(nav, index) in nav_info"
            :key="index"
            @click="handleNav(index)"
            :class="{ active: index == activeIndex }"
          >
            {{ nav.title }}
          </li>
        </ul>
        <div style="height: 40px"></div>
        <div class="img">
          <div v-for="(item, index) in nav_info" :key="index" class="myBox">
            <div class="myVideo">
              <video :src="item.img_url[0]" controls width="552px" height="335px"></video>
              <div style="width: 96px"></div>
              <video :src="item.img_url[1]" controls width="552px" height="335px"></video>
            </div>
            <div class="video-title">
              <div class="left-title">{{ item.video_title[0] }}</div>
              <div class="right-title">{{ item.video_title[1] }}</div>
            </div>
            <div class="desc"><span>{{item.desc}}</span></div>
          </div>
        </div>
      </div>
    </div>

    <div class="content" id="advantage">
      <h1 style="text-align: center">亮点优势</h1>
      <div style="height: 70px"></div>
      <div class="advantage">
        <div class="item" v-for="item, index in advantage_info" :key="index">
          <h1>{{item.title}}</h1><br>
          <span>{{item.desc}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav_info: [
        {
          title: "部件提示",
          desc: "选中部件提示，移动鼠标到注塑机模型的任意位置，系统会红色标签提示部件的具体名称,选中注塑机典型结构及部件的零件树，注塑机模型中的相应部件将会红色突出显示。",
          img_url: [
            require("@/assets/images/product/pseudoOperation/bjts.mp4"),
            require("@/assets/images/product/pseudoOperation/sxjg.mp4"),
          ],
          video_title: ["部件提示", "典型结构及零件树提示"],
        },
        {
          title: "手动、半自动、全自动",
          desc: "在手动操作模式下，通过点击操作面板实现模具开合模、射台移动、注射、射退、制品顶出等操作在半自动或全自动模式下，半自动则执行一次成型周期后停止，全自动则会像实际生产一样连续生产。",
          img_url: [
            require("@/assets/images/product/pseudoOperation/sdms.mp4"),
            require("@/assets/images/product/pseudoOperation/bzd.mp4")
          ],
          video_title: ["手动模式", "半自动模式"]
        },
        {
          title: "成型过程观察",
          desc: "通过控制鼠标，对注塑机进行拖动、旋转、缩放操作，可以从任意角度观察注塑机的成型过程，通过隐藏注塑机外壳、底座等部件，对注塑机注射、锁模单元、模具等部件的成型细节观察。",
          img_url: [
            require("@/assets/images/product/pseudoOperation/xzsf.mp4"),
            require("@/assets/images/product/pseudoOperation/cxgc.mp4"),
          ],
          video_title: ["全方位观察注塑机", "成型观察"]
        },
        // {
        //   title: "智能工艺、缺陷修正",
        //   desc: "系统根据材料、模具、机器等信息，自动生成初始工艺参数，系统根据缺陷种类、程度、位置等信息，自动优化工艺参数，消除制品缺陷。",
        //   img_url: [
        //     require("@/assets/images/product/pseudoOperation/pseudo-operation4.mp4"),
        //     require("@/assets/images/product/pseudoOperation/pseudo-operation4-1.mp4"),
        //   ],
        //   video_title: ["自动生成工艺参数", "自动优化工艺参数"]
        // },
        {
          title: "操作教学、产品展示",
          desc: "点击操作教学菜单，系统将通过语音讲解、文字提示的方式指导用户使用注塑机，点击最佳视角菜单，可以观察到产品的三维模型，通过旋转、拖拽、缩放操作观察产品细节。",
          img_url: [
            require("@/assets/images/product/pseudoOperation/czjx.mp4"),
            require("@/assets/images/product/pseudoOperation/cpzs.mp4"),
          ],
          video_title: ["操作教学", "产品展示"]
        },
        // {
        //   title: "模型切换、模流分析",
        //   desc: "从注塑机操作面板界面通过下拉选择不同产品，切换产品模型，展开模流数据界面，选中不同的属性会有相关内容的介绍，同时播放分析过程动画。",
        //   img_url: [
        //     require("@/assets/images/product/pseudoOperation/pseudo-operation6.mp4"),
        //     require("@/assets/images/product/pseudoOperation/pseudo-operation6-1.mp4"),
        //   ],
        //   video_title: ["模型切换", "模流分析"]
        // },
      ],
      activeIndex: 0,
      advantage_info: [
        { title: '部署灵活', desc: '支持线下本地和云端部署，可按需选择模块部署。' },
        { title: '教学模式', desc: '在线教学，模拟真实注塑机操作' },
        { title: '定制服务', desc: '提供二次开发服务，与系统功能实现代码隔离，可为企业提供个性化定制服务。' },
      ],
      nav_data: [
        { name: "系统介绍", href: '#introduction' },
        { name: "核心功能", href: '#corefunction' },
        { name: "亮点优势", href: '#advantage' },
      ]
    };
  },
  mounted() {
    document.addEventListener('scroll', this.handleWheel)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleWheel);
  },
  methods: {
    handleWheel() {
      var targetUrl = document.getElementById('second-level-directory')
      var xncz = document.getElementById('xncz')
      var introduction = document.getElementById('introduction')
      var distanceToTop = xncz.getBoundingClientRect().top;
      const topOffset = introduction.getBoundingClientRect().top;

      if (distanceToTop < 141) {
        targetUrl.style.position = 'fixed'
        targetUrl.style.top = '90px'
      } else if (distanceToTop == 141) {
        targetUrl.style.top = '200px'
      }

      const updateLinkStyles = function(index) {
        const lis = document.querySelectorAll(".nav-link")
        lis.forEach((li, i) => {
          if (i === index) {
            li.style.borderBottom = '2px solid red';
          } else {
            li.style.borderBottom = 'none';
          }
        })
      }

      if (topOffset <= 333 && topOffset > -50) {
        updateLinkStyles(0)
      } else if (topOffset <= -50 && topOffset > -950) {
        updateLinkStyles(1);
      } else if (topOffset <= -950) {
        updateLinkStyles(2)
      }
    },
    handleNav(index) {
      this.activeIndex = index;
      var box = document.querySelectorAll(".myBox");
      for (let i = 0; i < box.length; i++) {
        box[i].style.transform = `translateX(${index * -1200}px)`;
        box[i].style.transition = "transform 0.5s ease-in-out";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.fun-content {
  .nav {
    margin: 0 auto;
    width: 1100px;
    display: flex;
    justify-content: space-around;
    li {
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .img {
    width: 1200px;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    margin-left: 50%;
    transform: translate(-50%);
    border-radius: 35px;
    background: white;
    box-sizing: border-box;
    .myVideo {
      display: flex;
    }
    .desc {
      width: 80%;
      margin: 70px auto;
      font-size: 20px;
      letter-spacing: 3px;
      color: #828181;
    }
    .video-title {
      width: 100%;
      display: flex;
      justify-content: space-around;
      font-size: 20px;
      letter-spacing: 3px;
    }
  }
}
#introduction, #corefunction, #advantage {
  scroll-margin-top: 200px;
}
</style>